import React, { FC } from 'react';
import './Menu.scss';
import { useNavigate } from 'react-router-dom';

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
  const navigate = useNavigate();

  function onQuizClick() {
    navigate("/quiz");
  }

  function onWordsClick() {
    navigate("/words");
  }

  return (
    <div className="menu" data-testid="Menu">
      <div className='menu-title'>
        <p>Welcome to Everyday English</p>
      </div>
      <div className='menu-container'>
        <div className='quiz-menu' onClick={onQuizClick}>
          <div className='text-menu'>Quiz</div>
        </div>
        <div className='word-menu' onClick={onWordsClick}>
          <div className='text-menu'>Words</div>
        </div>
      </div>
      <div className='feedback-container-menu' onClick={() => window.open("mailto:everyday.new.language@gmail.com?subject=Everryday English Feedback")}>
          I would appreciate your feedback - everyday.new.language@gmail.com
        </div>
    </div>
  )
};


export default Menu;
