import React, { FC, useState } from 'react';
import './Answer.scss';

interface AnswerProps {
  text: string,
  index: number,
  correctAnswer: string,
}

const Answer: FC<AnswerProps> = ({text, index, correctAnswer}) => {
  const [className, setClassName] = useState<string>("answer");

  function handleAnswerClick() {
    if (text === correctAnswer) {
      setClassName("answer-correct");
      return;
    }
    setClassName("answer-wrong")
  }

  return (
    <div className={className} data-testid="Answer" onClick={handleAnswerClick}>
      <p className='answer-text'>{String.fromCharCode(index + 65) + ". " + text}</p>
    </div>
  )
};

export default Answer;
