// Timer.tsx

import React, { useState, useEffect } from 'react';

interface TimerProps {
  onTimerEnd: () => void;
  timerRunning: boolean;
}

const Timer: React.FC<TimerProps> = ({ onTimerEnd, timerRunning }) => {
  const initialTime = 120;
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timerRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerRunning, timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      onTimerEnd(); // Call the parent method when the timer ends
    }
  }, [timeLeft, onTimerEnd]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div style={{ fontSize: '1.5em', fontWeight: '700' }}>
      {formatTime(timeLeft)}
    </div>
  );
};

export default Timer;