import { QuizQuestion, QuizDTO } from "./data";
import axios from 'axios';

const baseUrl = 'https://volu-api.com';

async function getData() {
  try {
    const response = await axios.get(baseUrl + '/english/api/daily/questions');
    const parsedQuestion: QuizQuestion[] = JSON.parse(response.data.question_json).questions;
    const quiz: QuizDTO = {
      id: response.data.id,
      questions: parsedQuestion,
    };
    return quiz;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getLinks() {
  try {
    const response = await axios.get(baseUrl + '/api/links');
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getWords(level: number) {
  try {
    const response = await axios.get(baseUrl + '/api/words?unit=' + level);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getUnitWords(level: number) {
  try {
    const response = await axios.get(baseUrl + '/api/unit-words?unit=' + level);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getImage(id: number) {
  try {
    const url = `${baseUrl}/api/image/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}

async function getSound(id: number) {
  try {
    const url = `${baseUrl}/api/sound/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}

export {getData, getLinks, getImage, getWords, getUnitWords, getSound};