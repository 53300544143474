import React, { FC } from 'react';
import './Footer.scss';

interface FooterProps {
  links: any[]
}

const Footer: FC<FooterProps> = ({links}) => (
  <div className="footer">
    {links.sort().map((link:any) => (
      <div className='link' key={link.id} onClick={() => window.location.href = "https://" + link.url}>
        <div className='link-text'>{link.url.replace(".org", "")}</div>
      </div>
    ))}
  </div>
);

export default Footer;
