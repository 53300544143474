import { animated, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import React, { useEffect, useState } from 'react';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { getSound } from '../../data/openAI';

interface Word {
    id: number,
    word: string;
    image: string;
    description: string;
    phonetic: string;
    sound: string;
}

interface AnimatedCardProps {
    word: Word;
    index: number;
    lastIndex: number;
    onSwipe: (direction: number, index: number) => void;
}

const baseUrl = "https://volu-api.com/api/image/";

const AnimatedCard: React.FC<AnimatedCardProps> = ({ word, index, lastIndex, onSwipe }) => {
    const [rotation, setRotation] = useState(0);
    const [language, setLanguage] = useState("description");
    const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }))
    const [audio, setAudio] = useState<HTMLAudioElement>(new Audio());

    useEffect(() => {
        if (index === lastIndex) {
            getSound(word.id)
              .then(result => {
                const newAudio = new Audio(`data:audio/wav;base64,${result}`);
                newAudio.volume = 0.25;
                newAudio.load();
                setAudio(newAudio);
              })
              .catch(error => {
                console.error("Error loading sound:", error);
              });
        }

        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            setLanguage(getLanguage(storedLanguage));
        }
    }, [word, index, lastIndex]);

    function getLanguage(value: string): string {
        switch(value.toLowerCase()) {
            case "gb":
                return "description";
            case "ua":
                return "ukrainian";
            case "tr":
                return "turkish";
            case "sk":
                return "slovak";
            case "pt":
                return "portuguese";
            case "de":
                return "german";
            case "fr":
                return "french";
            case "es":
                return "spanish";
            case "cn":
                return "chinese";
            case "it":
                return "italian";
            default:
                return "description";
        }
    }

    const resetRotationAndPosition = () => {
        setRotation(0);
        api.start({ x: 0, y: 0 });
    };

    const bind = useGesture({
        onDrag: ({ down, dragging, movement: [x, y] }) => {
        api.start({ x, y });
        if (down) {
            let newRotation = x * 0.003 * (180 / Math.PI);
            newRotation = Math.max(-70, Math.min(70, newRotation));
            setRotation(newRotation);
        }
        if (!dragging) {
            if (x > 120) {
                onSwipe(1, index);
            }
            if (x < -120) {
                onSwipe(-1, index);
            }
        }
        },
        onPointerUp: resetRotationAndPosition
    });

    const onSoundClick = () => {
        audio.play();
    };

    return (
        <>
        {
            index === lastIndex ? (
                <animated.div
                    {...bind()}
                    className="swiper-div"
                    style={{
                        x: x,
                        y: y,
                        transform: `rotate(${rotation}deg)`,
                        transformOrigin: 'bottom center',
                        touchAction: 'none',
                        backgroundImage: `url(${baseUrl + word.id + ")"}`
                    }}
                >
                    <div className='word-container'>
                        <div className='word-name' onClick={onSoundClick}>
                            <span className='word-name-text'>{word?.word}</span>
                            <div className='phonetic-sound-container'>
                                <span className='word-phonetic'>{word?.phonetic}</span>
                                <div><VolumeUpIcon fontSize='large' className='sound-effect-icon'></VolumeUpIcon></div>
                            </div>
                        </div>
                        <div className='word-description'>
                            {word && (word as any)[language]}
                        </div>
                    </div>
                </animated.div>
            ) : (
                index > lastIndex - 5 && <div
                    className="swiper-div-fake"
                    style={{
                        transform: `rotate(${Math.random() * 15}deg)`,
                        backgroundImage: `url(${baseUrl + word.id + ")"}`
                    }}
                />
            )}
        </>
    );
};

export default AnimatedCard;