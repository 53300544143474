import React, { FC } from 'react';
import './Question.scss';
import Answer from '../Answer/Answer';
import { QuizQuestion } from '../../data/data';

interface QuestionProps {
  question: QuizQuestion
  index: number
}

const Question: FC<QuestionProps> = ({ question, index }) => {
  return(
    <div className="question" data-testid="Question">
      <p className='question-text'>{index + ". " + question.question}</p>
      <div className='answer-container'>
        {
          question.choices.map((option, index) => (
            <Answer key={index} text={option} index={index} correctAnswer={question.answer}></Answer>
          ))
        }
      </div>
    </div>
  )
};

export default Question;
