import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Language.scss';

interface LanguageOption {
  value: string;
  label: string;
  icon: () => JSX.Element;
}

const languageOptions: LanguageOption[] = [
  { value: 'gb', label: 'ENG', icon: () => <span className="gb flag-icon"></span> },
  { value: 'ua', label: 'UKR', icon: () => <span className="ua flag-icon"></span> },
  { value: 'tr', label: 'TUR', icon: () => <span className="tr flag-icon"></span> },
  { value: 'sk', label: 'SVK', icon: () => <span className="sk flag-icon"></span> },
  { value: 'de', label: 'GER', icon: () => <span className="de flag-icon"></span> },
  { value: 'es', label: 'ESP', icon: () => <span className="es flag-icon"></span> },
  { value: 'pt', label: 'POR', icon: () => <span className="pt flag-icon"></span> },
  { value: 'fr', label: 'FRA', icon: () => <span className="fr flag-icon"></span> },
  { value: 'cn', label: 'CHN', icon: () => <span className="cn flag-icon"></span> },
  { value: 'it', label: 'ITA', icon: () => <span className="it flag-icon"></span> }
];

const LanguagePicker: React.FC = () => {
  const [selectedLanguageValue, setSelectedLanguageValue] = useState<string>(
    () => {
      const storedLanguageValue = localStorage.getItem('selectedLanguage');
      return storedLanguageValue ? storedLanguageValue : 'gb';
    }
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguageValue);
  }, [selectedLanguageValue]);

  const selectedLanguageOption = languageOptions.find(option => option.value === selectedLanguageValue);

  const customStyles = {
    control: () => ({
        border: '1px solid #ced4da',
        borderRadius: '15px',
    }),
    valueContainer: () => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.4em'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: () => ({
        display: 'none',
    }),
    menu: (provided: any) => ({
        ...provided,
        width: 'auto',
    }),
    option: (provided: any) => ({
        ...provided,
        whiteSpace: 'nowrap',
    }),
    input: (baseStyles: any) => ({
        width: 0,
        color: 'transparent',
        readOnly: true,
    })
  };

  const handleChange = (selectedOption: LanguageOption | null) => {
    if (selectedOption) {
      setSelectedLanguageValue(selectedOption.value);
    }
    window.location.reload();
  };

  return (
    <div>
      <Select
        value={selectedLanguageOption}
        onChange={handleChange}
        options={languageOptions}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        formatOptionLabel={(option) => (
          <div className='language-select'>
            <div className='language-icon'>{option.icon && option.icon()}</div>
            <div className='language-label'>{option.label}</div>
          </div>
        )}
        isSearchable={false}
        styles={customStyles}
      />
    </div>
  );
};

export default LanguagePicker;
