import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './components/Main/Main';
import Menu from './components/Menu/Menu';
import WordMatch from './components/WordMatch/WordMatch';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/quiz" element={<Main />} />
          <Route path="/words" element={<WordMatch />} />
        </Routes>
      </BrowserRouter>
      <div className="paperOverlay"></div>
      <Helmet>
        <title>Everyday English | Grammar & Language Tests | Vocabulary Boost</title>
        <meta name="description" content="Welcome to Everyday English! Test your English grammar, language level, and vocabulary skills online with our AI-powered quizzes. Challenge yourself with our comprehensive vocabulary exercises and improve your English proficiency today!"/>
      </Helmet>
    </div>
  );
}

export default App;
