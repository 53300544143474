import React, { FC, useEffect, useState } from 'react';
import './Main.scss';
import Question from '../Question/Question';
import Header from '../Header/Header';
import { getData, getLinks} from '../../data/openAI';
import { QuizDTO } from '../../data/data';
import Footer from '../Footer/Footer';

interface MainProps {}

const Main: FC<MainProps> = () => {
  const [quizData, setQuizData] = useState<QuizDTO>();
  const [links, setLinks] = useState<any[]>([]);

  useEffect(() => {
    getData().then((result) => {
      setQuizData(result);
    });
    getLinks().then((result) => {
      const currentDomain = window.location.hostname;
      setLinks(result.filter((link: any) => !link.url.includes(currentDomain)));
    })
  }, []);

  return (
    <div className="main" data-testid="Main">
      { quizData && <Header id={quizData.id}/>}
      <div className='content'>
        {
          quizData?.questions?.map((option: any, index: number) => (<Question key={index} question={option} index={index + 1}/>))
        }
      </div>
      {links && <Footer links={links}/>}
      <div className='about-me'>
        <a href="https://www.buymeacoffee.com/volu" target="_blank" rel="noopener noreferrer">
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
            alt="Buy Me A Coffee"
            style={{ height: '60px', width: '217px' }}
          />
        </a>
        <div className='feedback-container' onClick={() => window.open("mailto:everyday.new.language@gmail.com?subject=Everryday English Feedback")}>
          I would appreciate your feedback - everyday.new.language@gmail.com
        </div>
      </div>
    </div>
  );
};

export default Main;
