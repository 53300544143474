import React, { FC } from 'react';
import './Header.scss';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  id: number,
}

const Header: FC<HeaderProps> = ({id}) => {
  const navigate = useNavigate();

  function onBackClick() {
    navigate("/");
  }

  return (
    <div className="header" data-testid="Header">
      <div className='menu-button' onClick={onBackClick}><span style={{fontSize: "1.2em"}}>{"<"}</span> Menu</div>
      <div>
        <div className='description'>Daily Updating AI-Powered Test</div>
      </div>
      <p className='day-counter'>DAY #{id}</p>
    </div>
  );
};


export default Header;
