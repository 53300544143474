import { FC, useEffect, useState } from 'react';
import { getUnitWords, getWords } from '../../data/openAI';
import AnimatedCard from './AnimatedCard';
import './WordMatch.scss';
import { useNavigate } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Timer from './Timer';
import { Helmet } from 'react-helmet';
import LanguagePicker from '../Language/LanguagePicker';

interface SaveData {
  points: number,
  level: number,
  words: { [key: string]: number },
  rightSwipes: number,
  leftSwipes: number,
  learnedWords: number,
  passedExams: number,
  lostExams: number,
}

interface WordMatchProps {}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorSecondary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#ffbc2b' : '#ffbc2b',
  },
  width: "100%",
  border: "solid 1px black"
}));

const WordMatch: FC<WordMatchProps> = () => {
  const [feedWords, setFeedWords] = useState<any[]>([]);
  const [words, setWords] = useState<any[]>([]);
  const [unitWords, setUnitWords] = useState<any[]>([]);
  const [topWords, setTopWords] = useState<any[]>([]);
  const [bottomWords, setBottomWords] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isInExamMode, setIsInExamMode] = useState<boolean>(false);
  const [isExamLost, setIsExamLost] = useState<boolean>(false);
  const [isExamFinished, setIsExamFinished] = useState<boolean>(false);
  const [timerRunning, setTimerRunning] = useState<boolean>(true);
  const [points, setPoints] = useState<number>(0);
  const [hearts, setHearts] = useState<number>(3);
  const [level, setLevel] = useState<number>(1);
  const [language, setLanguage] = useState("description");
  const [savedData, setSavedData] = useState<SaveData>({
    points: 0,
    level: 1,
    words: {},
    rightSwipes: 0,
    leftSwipes: 0,
    learnedWords: 0,
    passedExams: 0,
    lostExams: 0
  });

  const normalise = (value: number) => (value * 100) / toNextLevelPoints;
  const navigate = useNavigate();
  const imageAmount = 10;
  const toNextLevelPoints = 3;

  useEffect(() => {
    const savedDataString = localStorage.getItem("saveData");
    const savedData: SaveData | null = savedDataString ? JSON.parse(savedDataString) : null;
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
        setLanguage(getLanguage(storedLanguage));
    }

    const levelToLoad = savedData ? savedData.level : 1;

    getWords(levelToLoad)
      .then(result => {
        loadData(result, savedData);
      })
      .catch(error => {
        console.error("Error loading data:", error);
      });

    getUnitWords(levelToLoad)
    .then(result => {
      setUnitWords(result);
    })
    .catch(error => {
      console.error("Error loading data:", error);
    });
  }, []);

  function loadData(result: any[], savedData: SaveData | null) {
    setWords(result);
    setFeedWords(shuffleArray(result, findProbabilities(result), imageAmount));
    setLoading(false);

    if (savedData) {
      setSavedData(savedData);
      setLevel(savedData.level);
      setTopWords(findTop5LowestWords(savedData.words));
      setBottomWords(findBottom5LowestWords(savedData.words));
      setPoints(savedData.points);
    }
  }

  function saveData() {
    localStorage.setItem('saveData', JSON.stringify(savedData));
    setTopWords(findTop5LowestWords(savedData.words));
    setBottomWords(findBottom5LowestWords(savedData.words));
  }

  function getLanguage(value: string): string {
    switch(value.toLowerCase()) {
        case "gb":
            return "description";
        case "ua":
            return "ukrainian";
        case "tr":
            return "turkish";
        case "sk":
            return "slovak";
        case "pt":
            return "portuguese";
        case "de":
            return "german";
        case "fr":
            return "french";
        case "es":
            return "spanish";
        case "cn":
            return "chinese";
        case "it":
            return "italian";
        default:
            return "description";
    }
  }

  function findTop5LowestWords(words: { [key: string]: number }): [string, number][] {
    const wordsArray: [string, number][] = Object.entries(words);
    wordsArray.sort((a, b) => a[1] - b[1]);
    const top5Lowest: [string, number][] = wordsArray.slice(0, 5);
    return top5Lowest;
  }

  function findBottom5LowestWords(words: { [key: string]: number }): [string, number][] {
    const wordsArray: [string, number][] = Object.entries(words);
    wordsArray.sort((a, b) => b[1] - a[1]);
    const top5Lowest: [string, number][] = wordsArray.slice(0, 5);
    return top5Lowest;
  }

  function shuffleArray(array: any[], probabilities: number[], numberOfImages: number) {
    const shuffledArray = [...array];
    const n = shuffledArray.length;
    // Calculate the total sum of probabilities
    const totalProbability = probabilities.reduce((acc, val) => acc + val, 0);
    // Normalize probabilities so they sum up to 1
    const normalizedProbabilities = probabilities.map(prob => prob / totalProbability);
    const generatedImages = [];
    for (let i = n - 1; i > 0; i--) {
        // Generate a random number biased by probabilities
        let randomIndex = 0;
        let randomNumber = Math.random();
        for (let j = 0; j < i; j++) {
            if (randomNumber < normalizedProbabilities[j]) {
                randomIndex = j;
                break;
            }
            randomNumber -= normalizedProbabilities[j];
        }
        // Swap elements
        [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
        // Push the shuffled image to the generated images array
        generatedImages.push(shuffledArray[i]);
        // If the number of generated images reaches the desired count, break the loop
        if (generatedImages.length === numberOfImages) {
            break;
        }
    }
    return generatedImages;
  }

  function findProbabilities(array: any[]) {
    const probabilities = [];
    for (let i = 0; i < array.length; i++) {
      const word = savedData.words[array[i].word];
      if (word == null) {
        probabilities.push(1);
      } else {
        probabilities.push(word);
      }
    }
    return probabilities;
  }

  function popItem<T>(array: T[]): T[] {
    const newArray = [...array];
    newArray.pop();
    return newArray;
  }

  function storeItemLeft(array: any[]) {
    if (!isInExamMode) {
      savedData.leftSwipes+=1;
      const newArray = [...array];
      const word = newArray.pop();
      if (savedData.words[word.word]) {
        savedData.words[word.word] *= 1.05;
      } else {
        savedData.words[word.word] = 3;
      }
      return newArray;
    } else {
      const newArray = [...array];
      const word = newArray.pop();
      if (word.isCorrect) {
        const newHearts = hearts - 1;
        setHearts(newHearts);
        if (newHearts === 0) {
          onExamLost();
        }
      }
    }
  }

  function storeItemRight(array: any[]) {
    if (!isInExamMode) {
      savedData.rightSwipes+=1;
      const newArray = [...array];
      const word = newArray.pop();
      if (savedData.words[word.word]) {
        savedData.words[word.word] *= 0.9;
      } else {
        savedData.words[word.word] = 0.1;
        savedData.learnedWords += 1;
      }
      return newArray;
    } else {
      const newArray = [...array];
      const word = newArray.pop();
      if (!word.isCorrect) {
        const newHearts = hearts - 1;
        setHearts(newHearts);
        if (newHearts === 0) {
          onExamLost();
        }
      }
    }
  }

  function addPoints() {
    const newPoints = points + 1;
    savedData.points = newPoints;
    setPoints(newPoints);
    saveData();
  }

  function onNextLevel() {
    const newLevel = level + 1;
    savedData.level = newLevel;
    setLevel(newLevel);
  }

  function onExamLost() {
    setTimerRunning(false);
    setIsExamLost(true);
    setIsExamFinished(true);
  }

  function onExamPassed() {
    setTimerRunning(false);
    setIsExamLost(false);
    setIsExamFinished(true);
    if (points === toNextLevelPoints - 1) {
      onNextLevel();
      setPoints(0);
      savedData.points = 0;
      saveData();
    } else {
      addPoints();
    }

  }

  function onSwipe(direction: number, index: number) {
    if(direction === 1) {
      storeItemRight(feedWords);
    } else {
      storeItemLeft(feedWords);
    }
    if (!isInExamMode) {
      const popedArray = popItem(feedWords);
      setFeedWords(popedArray);
      if (index === 5) {
        const newArray = shuffleArray(words, findProbabilities(words), imageAmount);
        setFeedWords([...newArray, ...popedArray]);
      }
    } else {
      const popedArray = popItem(feedWords);
      if (popedArray.length <= 0) {
        onExamPassed();
      }
      setFeedWords(popedArray);
    }
    saveData();
  }

  function onBackClick() {
    navigate("/");
  }

  function onExamClick() {
    if (!isInExamMode) {
      savedData
      generateExam();
    }
  }

  function generateExamArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function shuffleDescriptionArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
  }

  function generateExam() {
    let examWords = unitWords;
    examWords = generateExamArray(examWords).slice(0, 10);
    const oldDescriptions = examWords.map(word => word[language]);
    const descriptions = examWords.slice(0, 6).map(word => word[language]);
    shuffleDescriptionArray(descriptions);
    for (let i = 0; i < examWords.slice(0, 6).length; i++) {
      examWords[i][language] = descriptions[i];
    }
    const newDescriptions = examWords.map(word => word[language]);
    for (let i = 0; i < examWords.length; i++) {
      const oldIndex = oldDescriptions.findIndex(d => d === examWords[i][language]);
      const newIndex = newDescriptions.findIndex(d => d === examWords[i][language]);
      if (oldIndex === newIndex) {
        examWords[i].isCorrect = true;
      } else {
        examWords[i].isCorrect = false;
      }
    }
    setFeedWords(generateExamArray(examWords));
    setIsInExamMode(true);
  }

  function onTimerEnd() {
    onExamLost();
  }

  function onContinueLearningClick() {
    window.location.reload();
  }

  if (isLoading)
    return (<div className='loading-words'><div style={{textAlign: "center"}}>Loading ...</div></div>)

  return (
    <div className="word-match" data-testid="WordMatch">
      <Helmet>
        <title>Everyday English | Vocabulary Boost | Words Learning</title>
        <meta name="description" content="Welcome to Everyday English! Enhance your English vocabulary skills through interactive and engaging words learning experiences. Start your journey to expand your English voca bulary words today!"/>
      </Helmet>
      <div className='words-header'>
        <div className='menu-button-words' onClick={onBackClick}><span style={{fontSize: "1em"}}>{"<"}</span> Menu</div>
        <div className='progress-words-container'>
          <div className='progress-bar-container'>
            <BorderLinearProgress
              className='progress-words'
              color="primary"
              variant="determinate"
              value={normalise(points)}
            />
            <div className='progress-words-text'>
              Unit {level}
            </div>
          </div>
          <div
            className={isInExamMode ? 'exam-icon' : 'progress-icon'}
          >
          </div>
        </div>
        <div className='language-picker-desktop'>
          <LanguagePicker></LanguagePicker>
        </div>
        <div className='about-me-words-desktop'>
          <a href="https://www.buymeacoffee.com/volu" target="_blank" rel="noopener noreferrer">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
              alt="Buy Me A Coffee"
              style={{ height: '45px', width: '160px' }}
            />
          </a>
        </div>
      </div>
      { isInExamMode &&
        <div className='exam-rules-params'>
          <div className='exam-hearts'>
            {
              [...Array(3)].map((_, index) =>
                index < hearts ? <FavoriteIcon key={index} style={{color: "#f26661", fontSize: "1.5em"}}/> : <FavoriteBorderIcon key={index} style={{fontSize: "1.5em"}}/>
              )
            }
          </div>
          <Timer onTimerEnd={onTimerEnd} timerRunning={timerRunning}></Timer>
        </div>
      }
      <div className='main-container'>
        { !isInExamMode ?
            <div className='tip-left'>
              Swipe left <br/> if you don't know the word.
            </div>
          :
            <div className='tip-left' style={{fontSize: "1.1em"}}>
              Swipe left <br/> if the description is wrong
            </div>
        }
        <div className='cards-container'>
          {
            !isExamFinished && feedWords.map((word, index) => (
              <AnimatedCard
                key={index}
                word={word}
                index={index}
                lastIndex={feedWords.length - 1}
                onSwipe={onSwipe}
              />
            ))
          }
          {
            isExamFinished && isExamLost &&
              <div className='exam-result-text'>
                <div className='words-exam-lost'>
                </div>
                <div>
                  <strong>Ohh, you just failed the exam.</strong> <br/> But don't worry, keep training, and you will be able to prove your knowledge.
                </div>
                <div className='exam-result-button' onClick={onContinueLearningClick}>
                  Continue learning
                </div>
              </div>
          }
          {
            isExamFinished && !isExamLost &&
            <div className='exam-result-text'>
              <div className='words-exam-passed'>
              </div>
              <div>
                <strong>Congratulations, you just passed the exam.</strong> <br/>You are really good at this. As a reward, we have prepared 50 new words for you at the next level.
              </div>
              <div className='exam-result-button' onClick={onContinueLearningClick}>
                Continue learning
              </div>
            </div>
          }
        </div>
        { !isInExamMode ?
            <div className='tip-right'>
              Swipe right <br/> if you know the word.
            </div>
          :
            <div className='tip-right' style={{fontSize: "1.1em"}}>
              Swipe right <br/> if the description is correct.
            </div>
        }
      </div>
      { !isInExamMode ?
            <div className='tip-mobile'>
              Swipe left if you don't know the word. <br/>
              Swipe right if you know the word.
            </div>
          :
            <div className='tip-mobile' style={{fontSize: "1.1em"}}>
              Swipe left if the description is wrong. <br/>
              Swipe right if the description is correct.
            </div>
      }
      {
        isInExamMode &&
        <div className='exam-tip-text'>
          Pay attention and Don't reload the page.
        </div>
      }
      {
        !isInExamMode && points === 2 &&
        <div className='exam-tip-text'>
          Pass the Exam to get more words.
        </div>
      }
      { !isInExamMode && <div className='start-exam' onClick={onExamClick}>Start Exam</div> }
      <div className='info-container'>
        <div className='words-info'>
          <div className='words-info-title-text' style={{textAlign: "center"}}>
              <span style={{color: "#007bff"}}>You've learned {savedData.learnedWords} words.</span>
              <br/> You need <span style={{color: "#007bff"}}>{Math.max(toNextLevelPoints - points, 1)} more exams</span> for the next unit.
          </div>
          <Divider orientation="horizontal" variant="fullWidth" flexItem></Divider>
          <div>
            <div className='words-info-title-text' style={{textAlign: "center"}}>
                Units: <span style={{color: "#007bff"}}>{level} / 9</span>
            </div>
            <div className='words-info-title-text' style={{textAlign: "center"}}>
                Swiped <span style={{color: "#28a745"}}>right: {savedData.rightSwipes}</span>
            </div>
            <div className='words-info-title-text' style={{textAlign: "center"}}>
                Swiped <span style={{color: "#dc3545"}}>left: {savedData.leftSwipes}</span>
            </div>
          </div>
        </div>
        <Divider orientation="horizontal" variant="fullWidth" flexItem></Divider>
        <div className='words-stats'>
          <div className='best-words'>
            <div className='words-info-title-text' style={{color: "#28a745"}}>Best Words</div>
            {topWords.map(([word, _], index) => (<div key={index} className='words-info-text'>{word}</div>))}
          </div>
          <div className='worst-words'>
            <div className='words-info-title-text' style={{color: "#dc3545"}}>Worst Words</div>
            {bottomWords.map(([word, _], index) => (<div key={index} className='words-info-text'>{word}</div>))}
          </div>
        </div>
      </div>
      <div className='about-me-words-mobile'>
          <a href="https://www.buymeacoffee.com/volu" target="_blank" rel="noopener noreferrer">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
              alt="Buy Me A Coffee"
              style={{ height: '45px', width: '160px' }}
            />
          </a>
      </div>
      <div className='main-words-information'>
        <h2>How to Use the App</h2>
        <div>
          <div className="app-container">
            <h3>Goal of the App</h3>
            <p>The goal of our app is to help users enhance their vocabulary through an interactive and engaging learning experience focused on words learning. Here's how our app achieves this:</p>
            <ol>
                <li><strong>Word Association:</strong> Users can associate words with pictures, aiding in retention and recall.</li>
                <li><strong>Interactive Learning:</strong> Users engage with the app by swiping left or right, which affects the appearance probability of words, promoting learning through repetition.</li>
                <li><strong>Structured Learning:</strong> Words are organized into units, providing users with a clear path to track their progress and focus on specific word sets.</li>
                <li><strong>Exam Mode:</strong> Users can test their knowledge through an exam mode, where they must correctly identify word descriptions to pass.</li>
                <li><strong>Motivation:</strong> The app aims to keep users motivated by offering a structured learning experience and rewarding progress with access to new units of words.</li>
                <li><strong>Continuous Improvement:</strong> We update the app weekly with new words, encouraging users to continue expanding their vocabulary over time.</li>
            </ol>
            <p>We are committed to providing an enjoyable and effective platform for users to enhance their vocabulary skills through our vocabulary boost website.</p>

            <h3>Functionality:</h3>
            <ul>
                <li>Swiping left increases the probability of a word appearing, aiding in vocabulary retention.</li>
                <li>Swiping right decreases the probability of a word appearing, encouraging users to focus on learning.</li>
                <li>Words are divided into units for better organization and navigation.</li>
                <li>Each unit consists of 50 new words, accessible upon passing an exam.</li>
            </ul>

            <h3>Exam Mode:</h3>
            <ul>
                <li>Passing the exam requires at least 25 correct swipes from the new unit.</li>
                <li>Users must swipe right for correct word descriptions and left for incorrect ones during the exam.</li>
                <li>Failing the exam resets progress for the unit, necessitating the collection of words from the beginning.</li>
            </ul>
            <p><strong>Note:</strong> Users are advised not to reload the page during exam mode to avoid losing progress.</p>

            <h3>New Unit Access:</h3>
            <ul>
                <li>Successful completion of an exam grants access to a new unit with 50 additional words.</li>
                <li>The website continuously adds 100 new words weekly to enrich the learning experience.</li>
            </ul>

            <h3>Content:</h3>
            <ul>
                <li>Unit 1: B-2</li>
                <li>Unit 2: Common</li>
                <li>Unit 3: Cartoons</li>
                <li>Unit 4: Sports</li>
                <li>Unit 5: A-words</li>
                <li>Unit 6: B-words</li>
                <li>Unit 7: C-2</li>
                <li>Unit 8: C-2</li>
                <li>Unit 9: Kitchen</li>
            </ul>

            <h3>Conclusion:</h3>
            <p>The website aims to facilitate vocabulary improvement through interactive learning methods, emphasizing engagement and progress tracking.</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default WordMatch;